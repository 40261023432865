import React, { useState, useEffect } from 'react';
import { Save, Tag, X, Loader2, Plus, Clock, Menu } from 'lucide-react';
import { useJournalStore } from '../store/useJournalStore';
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '../contexts/ThemeContext';
import { useCollectionStore } from '../store/useCollectionStore';
import { AIAnalysis, AnalysisType } from '../types/aiAnalysis';
import { defaultTags } from '../constants/defaultTags';
import { useAutoSave } from '../hooks/useAutoSave';
import AIAnalysisPanel from './AIAnalysisPanel';
import UnsavedChangesModal from './UnsavedChangesModal';
import FloatingSaveButton from './FloatingSaveButton';
import CoverImageStrip from './CoverImageStrip';

interface JournalEditorProps {
  entryId?: string;
  onNavigate?: () => void;
  onOpenMenu: () => void;
}

export default function JournalEditor({ entryId, onNavigate, onOpenMenu }: JournalEditorProps) {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [analyses, setAnalyses] = useState<AIAnalysis[]>([]);
  const [isGeneratingAnalysis, setIsGeneratingAnalysis] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [showTagInput, setShowTagInput] = useState(false);
  const [customTag, setCustomTag] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const [justSaved, setJustSaved] = useState(false);
  const [lastSaved, setLastSaved] = useState<string | null>(null);
  const [selectedAnalysisType, setSelectedAnalysisType] = useState<AnalysisType>('summary');
  const [currentEntryId, setCurrentEntryId] = useState<string | null>(null);

  const { addEntry, updateEntry, getEntry, generateAnalysis } = useJournalStore();
  const { user } = useAuth();
  const { isAIEnabled } = useTheme();
  const { selectedCollectionId, collections, fetchCollections } = useCollectionStore();

  useEffect(() => {
    if (user && !collections.length) {
      fetchCollections(user.id);
    }
  }, [user, collections.length]);

  useEffect(() => {
    if (entryId) {
      const entry = getEntry(entryId);
      if (entry) {
        setCurrentEntryId(entry.id);
        setTitle(entry.title);
        setContent(entry.content);
        setSelectedTags(entry.tags);
        setAnalyses(entry.analyses);
        setLastSaved(entry.updatedAt);
      }
    }
  }, [entryId, getEntry]);

  useEffect(() => {
    if (entryId) {
      const entry = getEntry(entryId);
      if (entry) {
        const isModified = 
          title !== entry.title ||
          content !== entry.content ||
          JSON.stringify(selectedTags) !== JSON.stringify(entry.tags) ||
          JSON.stringify(analyses) !== JSON.stringify(entry.analyses);
        setHasUnsavedChanges(isModified);
      }
    } else {
      setHasUnsavedChanges(title.trim() !== '' || content.trim() !== '' || analyses.length > 0);
    }
  }, [title, content, selectedTags, analyses, entryId, getEntry]);

  const handleGenerateAnalysis = async (type: AnalysisType) => {
    if (!content.trim() || isGeneratingAnalysis || !user || !selectedCollectionId) return;

    setIsGeneratingAnalysis(true);
    try {
      const analysis = await generateAnalysis(content, type);
      const timestamp = new Date().toISOString();
      const newAnalyses = [
        ...analyses.filter(a => a.type !== type),
        { ...analysis, createdAt: timestamp }
      ];
      
      setAnalyses(newAnalyses);

      if (entryId) {
        await updateEntry(entryId, title, content, selectedTags, newAnalyses);
      } else {
        const newEntryId = await addEntry(
          title || 'Untitled Entry',
          content,
          selectedTags,
          newAnalyses,
          user.id,
          selectedCollectionId
        );
        if (newEntryId) {
          setLastSaved(timestamp);
        }
      }
    } catch (error) {
      console.error('Analysis generation error:', error);
    } finally {
      setIsGeneratingAnalysis(false);
    }
  };

  const handleSave = async () => {
    if (!title.trim() || !content.trim() || !user || !selectedCollectionId || isSaving) return;
    
    setIsSaving(true);
    
    try {
      const timestamp = new Date().toISOString();
      
      if (currentEntryId) {
        await updateEntry(currentEntryId, title, content, selectedTags, analyses);
      } else {
        const newEntryId = await addEntry(title, content, selectedTags, analyses, user.id, selectedCollectionId);
        setCurrentEntryId(newEntryId);
      }

      setHasUnsavedChanges(false);
      setJustSaved(true);
      setLastSaved(timestamp);
      setTimeout(() => setJustSaved(false), 2000);
    } catch (error: any) {
      console.error('Save error:', error);
    } finally {
      setIsSaving(false);
    }
  };

  useAutoSave({
    onSave: handleSave,
    hasUnsavedChanges,
    enabled: Boolean(title.trim() && content.trim())
  });

  const handleAddTag = (tag: string) => {
    if (!selectedTags.includes(tag)) {
      setSelectedTags([...selectedTags, tag]);
    }
    setShowTagInput(false);
    setCustomTag('');
  };

  const handleRemoveTag = (tag: string) => {
    setSelectedTags(selectedTags.filter(t => t !== tag));
  };

  if (!selectedCollectionId) {
    return (
      <div className="flex items-center justify-center min-h-[50vh]">
        <div className="text-center">
          <p className="text-slate-500 dark:text-slate-400">
            Please select a journal to start writing
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6 pb-24">
      <CoverImageStrip collectionId={selectedCollectionId} />

      <div className="sticky top-0 z-10 bg-gradient-to-br from-slate-50 to-white dark:from-slate-900 dark:to-slate-800 py-4">
        <div className="flex items-center gap-4">
          <button
            onClick={onOpenMenu}
            className="md:hidden p-2 rounded-lg hover:bg-slate-100 dark:hover:bg-slate-800"
          >
            <Menu className="w-6 h-6 text-slate-600 dark:text-slate-300" />
          </button>
        </div>
      </div>

      <div className="space-y-4">
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Entry title..."
          className="w-full px-4 py-2 text-xl font-medium bg-transparent border-none focus:outline-none text-slate-700 dark:text-slate-200 placeholder-slate-400 dark:placeholder-slate-500"
        />

        <div className="relative">
          <div className="flex flex-wrap gap-2 px-4 mb-4">
            {selectedTags.map(tag => (
              <span
                key={tag}
                className="px-2 py-1 text-sm rounded-full bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300 flex items-center gap-1"
              >
                {tag}
                <button
                  onClick={() => handleRemoveTag(tag)}
                  className="w-4 h-4 rounded-full hover:bg-blue-200 dark:hover:bg-blue-800 flex items-center justify-center"
                >
                  <X className="w-3 h-3" />
                </button>
              </span>
            ))}
            <button
              onClick={() => setShowTagInput(true)}
              className="px-2 py-1 text-sm rounded-full border border-slate-200 dark:border-slate-700 text-slate-500 dark:text-slate-400 hover:bg-slate-50 dark:hover:bg-slate-800 flex items-center gap-1"
            >
              <Plus className="w-3 h-3" />
              Add Tag
            </button>
          </div>

          {showTagInput && (
            <div className="absolute z-10 w-64 bg-white dark:bg-slate-800 rounded-lg shadow-lg border border-slate-200 dark:border-slate-700 p-2">
              <div className="mb-2">
                <input
                  type="text"
                  value={customTag}
                  onChange={(e) => setCustomTag(e.target.value)}
                  placeholder="Enter custom tag..."
                  className="w-full px-2 py-1 text-sm rounded border border-slate-200 dark:border-slate-700 bg-transparent focus:outline-none focus:ring-1 focus:ring-blue-500"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && customTag.trim()) {
                      handleAddTag(customTag.trim());
                    }
                  }}
                />
              </div>
              <div className="space-y-1">
                {defaultTags
                  .filter(tag => !selectedTags.includes(tag))
                  .map(tag => (
                    <button
                      key={tag}
                      onClick={() => handleAddTag(tag)}
                      className="w-full text-left px-2 py-1 text-sm rounded hover:bg-slate-100 dark:hover:bg-slate-700 text-slate-600 dark:text-slate-300"
                    >
                      {tag}
                    </button>
                  ))}
              </div>
            </div>
          )}

          <div className="space-y-4">
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="Write your thoughts..."
              className="w-full min-h-[300px] px-4 py-2 bg-transparent border-none focus:outline-none text-slate-600 dark:text-slate-300 placeholder-slate-400 dark:placeholder-slate-500 resize-none"
            />

            {lastSaved && (
              <div className="flex items-center gap-2 text-xs text-slate-400 dark:text-slate-500 px-4">
                <Clock className="w-3 h-3" />
                Last updated: {new Date(lastSaved).toLocaleString()}
              </div>
            )}
          </div>
        </div>
      </div>

      {isAIEnabled && (
        <AIAnalysisPanel
          content={content}
          analyses={analyses}
          isGenerating={isGeneratingAnalysis}
          onGenerate={handleGenerateAnalysis}
        />
      )}

      {showUnsavedModal && (
        <UnsavedChangesModal
          onSave={handleSave}
          onDiscard={() => {
            setShowUnsavedModal(false);
            onNavigate?.();
          }}
          onCancel={() => setShowUnsavedModal(false)}
        />
      )}

      <FloatingSaveButton
        onSave={handleSave}
        isSaving={isSaving}
        hasUnsavedChanges={hasUnsavedChanges}
        justSaved={justSaved}
        disabled={!title.trim() || !content.trim()}
      />
    </div>
  );
}