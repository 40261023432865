import { ChangelogEntry } from '../types/changelog';

export const changelog: ChangelogEntry[] = [
  {
    version: '1.31.0',
    date: '2024-02-16',
    changes: [
      'Fixed duplicate entries being created during auto-save',
      'Added unique entry check to prevent duplicates',
      'Improved entry state management during creation and updates'
    ]
  },
  {
    version: '1.30.0',
    date: '2024-02-16',
    changes: [
      'Fixed mobile menu visibility in journal list view',
      'Improved mobile menu button positioning',
      'Enhanced mobile UI consistency across all views',
      'Fixed authentication state management',
      'Optimized performance and reduced memory usage'
    ]
  },
  {
    version: '1.29.0',
    date: '2024-02-16',
    changes: [
      'Added multiple AI analysis types: Summary, Polish & Enhance, and Insights & Patterns',
      'Fixed mobile menu visibility and behavior',
      'Improved mobile UI layout and accessibility',
      'Enhanced AI panel positioning and responsiveness',
      'Fixed "New Entry" button visibility in empty journal state'
    ]
  },
  {
    version: '1.28.0',
    date: '2024-02-16',
    changes: [
      'Updated AI panel label orientation',
      'Improved AI panel visual hierarchy',
      'Enhanced readability of AI controls'
    ]
  }
];