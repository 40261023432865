import React, { useState, useEffect } from 'react';
import { Menu } from 'lucide-react';
import JournalEditor from './components/JournalEditor';
import JournalList from './components/JournalList';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Auth from './components/Auth';
import LandingPage from './components/LandingPage';
import UserProfile from './components/UserProfile';
import Settings from './components/Settings';
import CollectionGrid from './components/collections/CollectionGrid';
import { ThemeProvider } from './contexts/ThemeContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { useCollectionStore } from './store/useCollectionStore';
import { JournalCollection } from './types/journal';

function JournalApp() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showEntries, setShowEntries] = useState(true);
  const [selectedEntryId, setSelectedEntryId] = useState<string | undefined>();
  const [showProfile, setShowProfile] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showCollections, setShowCollections] = useState(true);
  const { selectCollection, selectedCollectionId } = useCollectionStore();
  const { user } = useAuth();

  const handleEditEntry = (id: string) => {
    setSelectedEntryId(id);
    setShowEntries(false);
    setIsSidebarOpen(false);
  };

  const handleNewEntry = () => {
    if (selectedCollectionId) {
      setSelectedEntryId(undefined);
      setShowEntries(false);
      setIsSidebarOpen(false);
    }
  };

  const handleSelectCollection = (collection: JournalCollection, startNewEntry?: boolean) => {
    selectCollection(collection.id);
    setShowCollections(false);
    setShowEntries(!startNewEntry);
    setIsSidebarOpen(false);
    if (startNewEntry) {
      setSelectedEntryId(undefined);
    }
  };

  const handleBackToCollections = () => {
    selectCollection(null);
    setShowCollections(true);
    setShowEntries(true);
    setSelectedEntryId(undefined);
    setIsSidebarOpen(false);
  };

  const handleShowEntries = () => {
    if (selectedCollectionId) {
      setShowEntries(true);
      setSelectedEntryId(undefined);
      setIsSidebarOpen(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-50 to-white dark:from-slate-900 dark:to-slate-800 transition-colors duration-300 relative">
      <div className="flex h-screen overflow-hidden">
        <Sidebar 
          isOpen={isSidebarOpen} 
          onClose={() => setIsSidebarOpen(false)}
          onShowEntries={handleShowEntries}
          onNewEntry={handleNewEntry}
          onShowProfile={() => setShowProfile(true)}
          onShowSettings={() => setShowSettings(true)}
          onShowCollections={handleBackToCollections}
          showCollectionsButton={!showCollections}
        />
        
        <main className="flex-1 overflow-y-auto">
          <div className="max-w-5xl mx-auto px-4 py-8">
            {showCollections ? (
              <CollectionGrid 
                onSelectCollection={handleSelectCollection}
                onOpenMenu={() => setIsSidebarOpen(true)}
              />
            ) : showEntries ? (
              <JournalList 
                onEditEntry={handleEditEntry}
                onBackToCollections={handleBackToCollections}
                onNewEntry={handleNewEntry}
                onOpenMenu={() => setIsSidebarOpen(true)}
              />
            ) : (
              <JournalEditor 
                entryId={selectedEntryId}
                onNavigate={handleShowEntries}
                onOpenMenu={() => setIsSidebarOpen(true)}
              />
            )}
          </div>
        </main>
      </div>
      <Footer />
      {showProfile && <UserProfile onClose={() => setShowProfile(false)} />}
      {showSettings && <Settings onClose={() => setShowSettings(false)} />}
    </div>
  );
}

function AppContent() {
  const { user, loading } = useAuth();
  const [showAuth, setShowAuth] = useState(false);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-slate-50 to-white dark:from-slate-900 dark:to-slate-800">
        <div className="animate-pulse flex flex-col items-center">
          <div className="w-12 h-12 bg-blue-500/20 dark:bg-blue-400/20 rounded-full mb-4"></div>
          <div className="h-4 w-24 bg-slate-200 dark:bg-slate-700 rounded"></div>
        </div>
      </div>
    );
  }

  if (!user) {
    return showAuth ? (
      <Auth />
    ) : (
      <LandingPage onGetStarted={() => setShowAuth(true)} />
    );
  }

  return <JournalApp />;
}

export default function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <SettingsProvider>
          <AppContent />
        </SettingsProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}