import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Plus, Book, Settings, Loader2, AlertCircle, RefreshCw, Menu } from 'lucide-react';
import { useCollectionStore } from '../../store/useCollectionStore';
import { useAuth } from '../../contexts/AuthContext';
import { loadSignedImageUrl } from '../../utils/imageLoader';
import CreateCollectionModal from './CreateCollectionModal';
import JournalSettingsModal from './JournalSettingsModal';
import { JournalCollection } from '../../types/journal';

interface CollectionWithSignedUrl extends JournalCollection {
  signedUrl?: string;
  imageLoading?: boolean;
  imageError?: boolean;
}

interface CollectionGridProps {
  onSelectCollection: (collection: JournalCollection, startNewEntry?: boolean) => void;
  onOpenMenu: () => void;
}

export default function CollectionGrid({ onSelectCollection, onOpenMenu }: CollectionGridProps) {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState<JournalCollection | null>(null);
  const [collectionsWithUrls, setCollectionsWithUrls] = useState<CollectionWithSignedUrl[]>([]);
  const [loadingImages, setLoadingImages] = useState(false);
  const { collections, fetchCollections, loading, error } = useCollectionStore();
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      fetchCollections(user.id);
    }
  }, [user, fetchCollections]);

  useEffect(() => {
    const loadImages = async () => {
      setLoadingImages(true);
      const collectionsWithSignedUrls = await Promise.all(
        collections.map(async (collection) => {
          if (collection.coverImage) {
            try {
              const signedUrl = await loadSignedImageUrl(collection.coverImage);
              return { 
                ...collection, 
                signedUrl,
                imageLoading: false,
                imageError: false
              };
            } catch (error) {
              console.error('Error loading image:', error);
              return { 
                ...collection,
                imageError: true,
                imageLoading: false
              };
            }
          }
          return collection;
        })
      );
      setCollectionsWithUrls(collectionsWithSignedUrls);
      setLoadingImages(false);
    };

    loadImages();
  }, [collections]);

  const handleCreateSuccess = (collection: JournalCollection) => {
    setShowCreateModal(false);
    onSelectCollection(collection, true);
  };

  const handleRetry = () => {
    if (user) {
      fetchCollections(user.id);
    }
  };

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <Loader2 className="w-8 h-8 text-blue-500 animate-spin mx-auto mb-4" />
          <p className="text-slate-600 dark:text-slate-400">Loading journals...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center max-w-md mx-auto p-6">
          <AlertCircle className="w-12 h-12 text-red-500 mx-auto mb-4" />
          <h2 className="text-xl font-semibold text-slate-900 dark:text-white mb-2">
            Unable to Load Journals
          </h2>
          <p className="text-slate-600 dark:text-slate-400 mb-4">
            {error}
          </p>
          <button
            onClick={handleRetry}
            className="px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-200 flex items-center gap-2 mx-auto"
          >
            <RefreshCw className="w-4 h-4" />
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="max-w-7xl mx-auto">
        <div className="sticky top-0 z-10 bg-gradient-to-br from-slate-50 to-white dark:from-slate-900 dark:to-slate-800 py-4">
          <div className="flex items-center justify-between mb-8">
            <div className="flex items-center gap-4">
              <button
                onClick={onOpenMenu}
                className="md:hidden p-2 rounded-lg hover:bg-slate-100 dark:hover:bg-slate-800"
              >
                <Menu className="w-6 h-6 text-slate-600 dark:text-slate-300" />
              </button>
              <h1 className="text-3xl font-light text-slate-700 dark:text-slate-200">
                My Journals
              </h1>
            </div>
            <button
              onClick={() => setShowCreateModal(true)}
              className="px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-200 flex items-center gap-2"
            >
              <Plus className="w-4 h-4" />
              New Journal
            </button>
          </div>
        </div>

        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
          variants={container}
          initial="hidden"
          animate="show"
        >
          {collectionsWithUrls.map((collection) => (
            <motion.div
              key={collection.id}
              variants={item}
              className="group cursor-pointer"
              onClick={() => onSelectCollection(collection)}
            >
              <div className="relative aspect-[4/3] rounded-lg overflow-hidden">
                {collection.signedUrl ? (
                  <div className="relative w-full h-full">
                    {collection.imageLoading && (
                      <div className="absolute inset-0 flex items-center justify-center bg-slate-100 dark:bg-slate-800">
                        <Loader2 className="w-8 h-8 text-blue-500 animate-spin" />
                      </div>
                    )}
                    <img
                      src={collection.signedUrl}
                      alt={collection.name}
                      className={`w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 ${
                        collection.imageLoading ? 'opacity-0' : 'opacity-100'
                      }`}
                      onLoad={() => {
                        setCollectionsWithUrls(prev =>
                          prev.map(c =>
                            c.id === collection.id
                              ? { ...c, imageLoading: false }
                              : c
                          )
                        );
                      }}
                      onError={() => {
                        setCollectionsWithUrls(prev =>
                          prev.map(c =>
                            c.id === collection.id
                              ? { ...c, imageError: true, imageLoading: false }
                              : c
                          )
                        );
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-full h-full bg-gradient-to-br from-blue-100 to-indigo-100 dark:from-blue-900/30 dark:to-indigo-900/30 flex items-center justify-center">
                    <Book className="w-12 h-12 text-blue-500/50" />
                  </div>
                )}
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent flex items-end p-4">
                  <div className="w-full">
                    <h3 className="text-xl font-medium text-white mb-1">
                      {collection.name}
                    </h3>
                    <p className="text-sm text-slate-200 line-clamp-2">
                      {collection.description}
                    </p>
                    <div className="flex items-center justify-between mt-2">
                      <span className="text-sm text-slate-300">
                        {collection.entryCount} {collection.entryCount === 1 ? 'entry' : 'entries'}
                      </span>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedCollection(collection);
                        }}
                        className="p-1 rounded-full hover:bg-white/20 text-white md:opacity-0 md:group-hover:opacity-100 transition-opacity duration-200"
                      >
                        <Settings className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}

          {/* Create New Journal Card */}
          <motion.div
            variants={item}
            className="cursor-pointer"
            onClick={() => setShowCreateModal(true)}
          >
            <div className="aspect-[4/3] rounded-lg border-2 border-dashed border-slate-200 dark:border-slate-700 hover:border-blue-500 dark:hover:border-blue-400 transition-colors duration-200 flex flex-col items-center justify-center gap-4 group">
              <div className="w-16 h-16 rounded-full bg-slate-100 dark:bg-slate-800 flex items-center justify-center group-hover:bg-blue-100 dark:group-hover:bg-blue-900/30 transition-colors duration-200">
                <Plus className="w-8 h-8 text-slate-400 group-hover:text-blue-500 dark:group-hover:text-blue-400 transition-colors duration-200" />
              </div>
              <p className="text-slate-400 group-hover:text-blue-500 dark:group-hover:text-blue-400 transition-colors duration-200">
                Create New Journal
              </p>
            </div>
          </motion.div>
        </motion.div>
      </div>

      {showCreateModal && (
        <CreateCollectionModal 
          onClose={() => setShowCreateModal(false)} 
          onSuccess={handleCreateSuccess}
        />
      )}

      {selectedCollection && (
        <JournalSettingsModal
          collection={selectedCollection}
          onClose={() => setSelectedCollection(null)}
        />
      )}
    </div>
  );
}